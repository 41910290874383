// import {
//   signInWithGooglePopup,
//   createUserProfileDocument,
// } from '../../utils/firebase/firebase.utils';

import { useForm } from "react-hook-form";
import Logo from "../../assets/image/common/Logo.png";
import bg_left from "../../assets/image/common/bg_left.png";
import "./reset-password.style.scss";
import { Link, useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { resetPassword } from "../../apiCall";
import toast from "react-hot-toast";
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";

const ResetPasswd = () => {
  const [, dispatch] = useStateValue();
  const location = useLocation();
  const navigate = useNavigate();
  

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      token:"",
      newPassword:"",
      confirmPassword:"",
    },
  });
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = value;
      if (key === "token"){
        setValue("token",value)
      }
    }
  }, [location.search]);
  const submitRequest = async (data) => {
    console.log(data)
    if (data.newPassword === data.confirmPassword){

      dispatch({ type: "SET_LOADING_STATUS", status: true });
      const re = await resetPassword(data);
      if (re?.status === 200) {
        toast.success(re?.data?.message);
        navigate("/dashboard");
      } else {
        toast.error(re?.response?.data?.message);
      }
      dispatch({ type: "SET_LOADING_STATUS", status: false });
    }
    else{
      toast.error("The new password and confirmed password are not matching.")
    }
  };

  return (
    <div className="reset-password">
      <div className="container">
        <div className="bg outer-child">
          <img src={bg_left} alt="bg_img" />
        </div>
        <div className="details outer-child">
          <img src={Logo} alt="" />
          <h1 className="login-txt">Reset Password</h1>
          <form onSubmit={handleSubmit((data) => submitRequest(data))}>
            <div className="input-wrapper">
              <div className="input-container">
                <label htmlFor="">
                 New Password. <span>*</span>
                </label>
                <input
                  type="password"
                  {...register("newPassword", {
                    required: "New Password is required.",
                  })}
                  placeholder="Enter New Password."
                />
                {errors.newPassword && (
                  <p className="errorMsg">{errors.newPassword.message}</p>
                )}
              </div>
              <div className="input-container">
                <label htmlFor="">
                  Confirm New Password. <span>*</span>
                </label>
                <input
                  type="password"
                  {...register("confirmPassword", {
                    required: "Confirm Password is required.",
                    minLength: {
                      value: 8,
                      message: "length should be greater then 8.",
                    },
                  })}
                  placeholder="Enter Confirm Password."
                />
                {errors.confirmPassword && (
                  <p className="errorMsg">{errors.confirmPassword.message}</p>
                )}
              </div>
            </div>

            <div className="submit-button">
              <div className="button-text">
                <button className="green-btn">Submit Request</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswd;
