import { useState } from "react";
import "./admin-dashboard-members.style.scss";
import { adminMemberList, userDetailChange } from "../../../../apiCall";
import { useQuery } from "@tanstack/react-query";
import { useStateValue } from "../../../../StateProvider";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../../../components/loader/loader.component";
import { RxCheckCircled } from "react-icons/rx";
import { RxCrossCircled } from "react-icons/rx";
import { MdNavigateNext } from "react-icons/md";
import ConformationModal from "../../../../components/modals/ConformationModal/ConformationModal";
import { useForm } from "react-hook-form";

const convertToDate = (str) => {
  var date = new Date(str);

  // Extract year, month, and day
  var year = date.getFullYear();
  // Note: JavaScript months are zero-based, so we add 1 to get the correct month
  var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Zero padding
  var day = date.getDate().toString().padStart(2, "0"); // Zero padding

  // Construct the desired date string
  var formattedDate = day + "-" + month + "-" + year;
  return formattedDate;
};

function AdminDashboardMembers(props) {
  const perPage = 10;
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);

  const [memberId, setMemberId] = useState("");
  const [memberStatus, setMemberStatus] = useState("");
  const [show, setShow] = useState(false);
  const [screenType, setScreenType] = useState("memberDetail");
  const [activeSearch, setActiveSearch] = useState("");
  const [memberIdSearchParams, setMemberIdSearchParams] = useState("");
  const [emailSearchParams, setEmailSearchParams] = useState("");

  const handleEmailChange = (e) => {
    setEmailSearchParams(e.target.value.toLowerCase());
  };
  const handleMemberIdChange = (e) => {
    setMemberIdSearchParams(e.target.value.toUpperCase());
  };
  const handlePanChange = (e) => {
    setValue("panNumber", e.target.value.toUpperCase(), {
      shouldValidate: true,
    });
  };
  const nextPage = () => {
    setPageNumber((prevPage) => prevPage + 1);
    // refetch()
  };
  const previousPage = () => {
    setPageNumber((prevPage) => prevPage - 1);
    // refetch()
  };

  const onSuccess = (re) => {
    if (re?.status === 200) {
      // setMemberData(re?.data?.data);
    } else if (re?.response?.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else {
      toast.error("Something went wrong.");
    }
  };
  const [activeFilter, setActiveFilter] = useState("ALL");

  const {
    isLoading: isMemberListLoading,
    data: memberListData,
    refetch,
  } = useQuery({
    queryKey: [
      "all-admin-member-list",
      pageNumber,
      activeFilter,
      memberIdSearchParams,
      emailSearchParams,
    ],
    queryFn: () =>
      adminMemberList(
        pageNumber,
        perPage,
        activeFilter,
        emailSearchParams,
        memberIdSearchParams,
      ),

    onSuccess,
  });
  const handleRefetch = () => {
    refetch();
  };
  const actionChange = (event, memberDetail) => {
    if (event.target.value === "UNBLOCK" || event.target.value === "BLOCK") {
      setMemberStatus(event.target.value);
      setMemberId(memberDetail._id);
      setShow(true);
    }
    if (event.target.value === "EDIT") {
      changeMemberDetail(memberDetail);
    }
  };
  const {
    register: changeUserDetail,
    handleSubmit: handleChangeUserDetail,
    formState: { errors: changeUserDetailError },
    setValue,
    replace,
  } = useForm({
    defaultValues: {
      memberId: "",
      name: "",
      countryCode: "+91",
      mobileNumber: "",
      email: "",
      aadharNumber: "",
      panNumber: "",
      houseNo: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      state: "",
      accountHolderName: "",
      accountIfscCode: "",
      accountNumber: "",
      userPackageType: "",
    },
  });

  const changeMemberDetail = (memberDetail) => {
    window.scrollTo(0, 0);
    setValue("memberId", memberDetail.member_id);
    setValue("name", memberDetail.user_name);
    setValue("mobileNumber", memberDetail.mobile_number);
    setValue("email", memberDetail.email);
    setValue("aadharNumber", memberDetail.aadhar_number);
    setValue("panNumber", memberDetail.pan_number);
    setValue("houseNo", memberDetail.address.house_no);
    setValue("streetAddress", memberDetail.address.street_address);
    setValue("postalCode", memberDetail.address.postal_code);
    setValue("city", memberDetail.city);
    setValue("state", memberDetail.state);
    setValue(
      "accountHolderName",
      memberDetail.account_details.account_holder_address
    );
    setValue("accountIfscCode", memberDetail.account_details.bank_ifsc_code);
    setValue("userPackageType", memberDetail.user_package_type);
    setValue("accountNumber", memberDetail.account_details.account_number);

    setScreenType("ChangeUserDetail");
  };
  const handleChangeMemberDeatil = async (data) => {
    dispatch({ type: "SET_LOADING_STATUS", status: true });
    window.scrollTo(0, 0);
    console.log(data);
    const re = await userDetailChange(data);
    console.log(re);
    if (re?.status === 200) {
      toast.success(re?.data?.message);
      setScreenType("memberDetail");
      refetch();
    } else if (re.response.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else if (re.response.status === 409) {
      toast.error("Email is already used.");
    } else {
      toast.error(re?.response?.data?.message);
    }
    dispatch({ type: "SET_LOADING_STATUS", status: false });
  };

  if (isMemberListLoading && !(memberIdSearchParams || emailSearchParams)) {
    return <Loader />;
  }
  switch (screenType) {
    case "memberDetail":
      return (
        <section className="admin-member">
          <div className="history-container">
            <div className="header-wrapper">
              <div className="title">Members</div>
              <div className="filter">
                <select
                  name=""
                  id=""
                  value={activeFilter}
                  onChange={(event) => {
                    setActiveFilter(event.target.value);
                    refetch();
                  }}
                >
                  <option value="ALL">All</option>
                  <option value="WEEK">Last 7 days</option>
                  <option value="MONTH">Last 30 days</option>
                </select>
              </div>
            </div>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>
                      <span
                        onClick={() => {
                          setActiveSearch("memberId");
                          setMemberIdSearchParams("");
                          setEmailSearchParams("");
                        }}
                      >
                        Member Id
                      </span>
                      <br />
                      {activeSearch === "memberId" && (
                        <div className="input-wrapper">
                          <div className="input-container">
                            <input
                              type="text"
                              placeholder="Search by Member Id.."
                              value={memberIdSearchParams}
                              onChange={handleMemberIdChange}
                            />{" "}
                          </div>
                        </div>
                      )}
                    </th>
                    <th>Name</th>
                    <th>Mobile Number</th>

                    <th>
                      <span
                        onClick={() => {
                          setActiveSearch("email");
                          setEmailSearchParams("");
                          setMemberIdSearchParams("");
                        }}
                      >
                        Email
                      </span>
                      <br />
                      {activeSearch === "email" && (
                        <div className="input-wrapper">
                          <div className="input-container">
                            <input
                              type="text"
                              placeholder="Search by Email.."
                              value={emailSearchParams}
                              onChange={handleEmailChange}
                            />
                          </div>{" "}
                        </div>
                      )}
                    </th>
                    <th>City</th>
                    <th>State</th>
                    <th>Package Amount</th>
                    <th>Joining Date</th>
                    <th>Is Verify</th>
                    <th>Is Blocked</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {memberListData?.data?.data[0]?.list ? (
                    memberListData.data.data[0].list.map((member, index) => (
                      <tr>
                        <td>{index + 1 + perPage * (pageNumber - 1)}.</td>
                        <td>{member.member_id}</td>
                        <td>{member.user_name}</td>
                        <td>{member.mobile_number}</td>
                        <td>{member.email}</td>
                        <td>{member.city}</td>
                        <td>{member.state}</td>
                        <td>
                          {member?.user_package_type === "PRO" ? "1350" : "550"}
                        </td>
                        <td>{convertToDate(member?.created_at)}</td>
                        <td>
                          {member.is_verify ? (
                            <div className="unblock">
                              <RxCheckCircled />
                            </div>
                          ) : (
                            <div className="block">
                              {" "}
                              <RxCrossCircled />
                            </div>
                          )}
                        </td>
                        <td>
                          {!member.is_blocked ? (
                            <div className="block">
                              {" "}
                              <RxCrossCircled />
                            </div>
                          ) : (
                            <div className="unblock">
                              <RxCheckCircled />
                            </div>
                          )}
                        </td>
                        <td>
                          <select
                            name=""
                            id=""
                            value={member.is_blocked ? "BLOCK" : "UNBLOCK"}
                            onChange={(event) => actionChange(event, member)}
                            disabled={member?.member_id === "AM240210001"}
                          >
                            <option value="UNBLOCK">Unblock</option>
                            <option value="BLOCK">Block</option>
                            <option value="EDIT">Edit</option>
                          </select>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {memberListData?.data?.data[0] && (
            <div className="pagination">
              <button
                type="button"
                className={`page-index ${
                  pageNumber === 1 ? "" : "active"
                } previous-btn`}
                disabled={pageNumber === 1}
                onClick={() => previousPage()}
              >
                <MdNavigateNext size={30} />
              </button>
              <div className="page-count">{pageNumber}</div>
              <button
                type="button"
                className={`page-index ${
                  Math.ceil(
                    memberListData?.data?.data[0].count.count / perPage
                  ) === pageNumber
                    ? ""
                    : "active"
                } next-btn`}
                disabled={
                  Math.ceil(
                    memberListData?.data?.data[0].count.count / perPage
                  ) === pageNumber ||
                  memberListData?.data?.data[0].count.count === 0
                }
                onClick={() => nextPage()}
              >
                <MdNavigateNext size={30} />
              </button>
            </div>
          )}
          <ConformationModal
            // handfun={cancelOrder}
            handleRefetch={handleRefetch}
            memberStatus={memberStatus}
            memberId={memberId}
            setModalOpen={setShow}
            show={show}
            onHide={() => setShow(false)}
          />
        </section>
      );
    case "ChangeUserDetail":
      return (
        <section className="add-member-form">
          <form
            onSubmit={handleChangeUserDetail((data) =>
              handleChangeMemberDeatil(data)
            )}
          >
            <div className="form-section-1 inner-form">
              <div className="title">Personal Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Name. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("name", {
                      required: "Name is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Name."
                  />
                  {changeUserDetailError.name && (
                    <p className="errorMsg">
                      {changeUserDetailError.name.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Mobile No. <span>*</span>
                  </label>
                  <input
                    type="number"
                    {...changeUserDetail("mobileNumber", {
                      required: "Mobile number is required.",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Mobile number is not valid.",
                      },
                    })}
                    placeholder="Enter Mobile No."
                  />
                  {changeUserDetailError.mobileNumber && (
                    <p className="errorMsg">
                      {changeUserDetailError.mobileNumber.message}
                    </p>
                  )}
                </div>

                <div className="input-container">
                  <label htmlFor="">
                    Email. <span>*</span>
                  </label>
                  <input
                    type="email"
                    {...changeUserDetail("email", {
                      required: "Email is required.",
                      pattern: {
                        value: "/^[^@ ]+@[^@ ]+.[^@ .]{2,}$/",
                        message: "Email is not valid.",
                      },
                    })}
                    placeholder="Enter Email."
                  />
                  {changeUserDetailError.email && (
                    <p className="errorMsg">
                      {changeUserDetailError.email.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Aadhar No. <span>*</span>
                  </label>
                  <input
                    type="number"
                    {...changeUserDetail("aadharNumber", {
                      required: "Aadhar number is required.",
                      pattern: {
                        value: /^[0-9]{12}/,
                        message: "Aadhar number is not valid.",
                      },
                    })}
                    placeholder="Enter Aadhar No."
                  />
                  {changeUserDetailError.aadharNumber && (
                    <p className="errorMsg">
                      {changeUserDetailError.aadharNumber.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">PAN No.</label>
                  <input
                    type="text"
                    {...changeUserDetail("panNumber", {
                      // required: "PAN number is required.",
                      pattern: {
                        value: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
                        message: "PAN number is not valid.",
                      },
                    })}
                    onChange={(event) => handlePanChange(event)}
                    placeholder="Enter PAN No."
                  />
                  {changeUserDetailError.panNumber && (
                    <p className="errorMsg">
                      {changeUserDetailError.panNumber.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section-2 inner-form">
              <div className="title">Address Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">House Name & No.</label>
                  <input
                    type="text"
                    {...changeUserDetail("houseNo", {
                      // required: "House name & number is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter House Name & No."
                  />
                  {changeUserDetailError.houseNo && (
                    <p className="errorMsg">
                      {changeUserDetailError.houseNo.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">Street Address.</label>
                  <input
                    type="text"
                    {...changeUserDetail("streetAddress", {
                      // required: "Street address is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Street Address."
                  />
                  {changeUserDetailError.streetAddress && (
                    <p className="errorMsg">
                      {changeUserDetailError.streetAddress.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Postal Code. <span>*</span>
                  </label>
                  <input
                    type="number"
                    {...changeUserDetail("postalCode", {
                      required: "Postal code is required.",
                      pattern: {
                        value: /^[0-9]{6}/,
                        message: "Postal code is not valid.",
                      },
                    })}
                    placeholder="Enter Postal Code."
                  />
                  {changeUserDetailError.postalCode && (
                    <p className="errorMsg">
                      {changeUserDetailError.postalCode.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    State. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("state", {
                      required: "State is required.",
                      maxLength: {
                        value: 20,
                        message: "length should be less then 20.",
                      },
                    })}
                    placeholder="Enter State."
                  />
                  {changeUserDetailError.state && (
                    <p className="errorMsg">
                      {changeUserDetailError.state.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    City. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("city", {
                      required: "City is required.",
                      maxLength: {
                        value: 20,
                        message: "length should be less then 20.",
                      },
                    })}
                    placeholder="Enter City."
                  />
                  {changeUserDetailError.city && (
                    <p className="errorMsg">
                      {changeUserDetailError.city.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section-3 inner-form">
              <div className="title">Account Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Account Holder Name. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("accountHolderName", {
                      required: "Account holder name is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Account Holder Name."
                  />
                  {changeUserDetailError.accountHolderName && (
                    <p className="errorMsg">
                      {changeUserDetailError.accountHolderName.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    IFSC Code. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("accountIfscCode", {
                      required: "IFSC code is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter IFSC Code."
                  />
                  {changeUserDetailError.accountIfscCode && (
                    <p className="errorMsg">
                      {changeUserDetailError.accountIfscCode.message}
                    </p>
                  )}
                </div>

                <div className="input-container">
                  <label htmlFor="">
                    Account No. <span>*</span>
                  </label>
                  <input
                    type="number"
                    {...changeUserDetail("accountNumber", {
                      required: "Account number is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Account No."
                  />
                  {changeUserDetailError.accountNumber && (
                    <p className="errorMsg">
                      {changeUserDetailError.accountNumber.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section-4 inner-form">
              <div className="title">Package Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Package. <span>*</span>
                  </label>
                  <select
                    type="text"
                    {...changeUserDetail("userPackageType", {
                      required: "Please select package",
                    })}
                    placeholder="Enter Account Holder Name."
                    defaultValue="BASIC"
                    disabled
                  >
                    <option value="BASIC">550/- </option>
                    <option value="PRO">1350/- </option>
                  </select>
                  {changeUserDetailError.userPackageType && (
                    <p className="errorMsg">
                      {changeUserDetailError.userPackageType.message}
                    </p>
                  )}
                  <p className="note">
                    {" "}
                    <span>Note:-</span>During payment, 18% GST is charged. The
                    value of this page is GST excluded.
                  </p>
                </div>
              </div>
            </div>
            <div className="btn-wrapper">
              <button className="green-btn submit-btn">
                Update Member Detail
              </button>
            </div>
          </form>
        </section>
      );
  }
}

export default AdminDashboardMembers;
