import React, { useState } from "react";
import "./dashboard-add-member.style.scss";
import profile_pic from "../../../../assets/image/default_profile_pic.png";
import { ReactComponent as GStatus } from "../../../../assets/icon/status_g_btn.svg";
import { ReactComponent as RStatus } from "../../../../assets/icon/status_r_btn.svg";
import server_error from "../../../../assets/image/common/500.png";
import error from "../../../../assets/image/common/No data.gif";

import { useForm } from "react-hook-form";
import { addMember, changeMember, memberList, resendPaymentLink } from "../../../../apiCall";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../../components/loader/loader.component";
import { useStateValue } from "../../../../StateProvider";
import { useNavigate } from "react-router-dom";
const convertToDate = (str)=>{
  var date = new Date(str);

  // Extract year, month, and day
  var year = date.getFullYear();
  // Note: JavaScript months are zero-based, so we add 1 to get the correct month
  var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Zero padding
  var day = date.getDate().toString().padStart(2, '0'); // Zero padding
  
  // Construct the desired date string
  var formattedDate = day + "-" + month + "-" + year;
  return formattedDate
}

const numberToString = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  // Add more mappings as needed
};
function DashboardAddMember(props) {
  const [, dispatch] = useStateValue();
  const navigate = useNavigate()

  const [screenType, setScreenType] = useState("memberDetail");
  
  const {
    isLoading: isMemberListLoading,
    data: memberListData,
    refetch,
  } = useQuery({
    queryKey: ["member-list"],
    queryFn: memberList,
    // staleTime:180000,

  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue:addMemberSetValue,
    reset
  } = useForm({
    defaultValues: {
      name: "",
      countryCode: "+91",
      mobileNumber: "",
      email: "",
      aadharNumber: "",
      panNumber: "",
      houseNo: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      state: "",
      accountHolderName: "",
      accountIfscCode: "",
      accountNumber: "",
      userPackageType: "",
    },
  });
  const handlePanChange = (e) => {
    addMemberSetValue('panNumber', e.target.value.toUpperCase(), { shouldValidate: true });
  };
  const {
    register: changeUserDetail,
    handleSubmit: handleChangeUserDetail,
    formState: { errors: changeUserDetailError },
    setValue,
    replace
  } = useForm({
    defaultValues: {
      memberId: "",
      name: "",
      countryCode: "+91",
      mobileNumber: "",
      email: "",
      aadharNumber: "",
      panNumber: "",
      houseNo: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      state: "",
      accountHolderName: "",
      accountIfscCode: "",
      accountNumber: "",
      userPackageType: "",
    },
  });

  const changeMemberDetail = (memberDetail) => {
    window.scrollTo(0, 0)
    setValue("memberId" ,memberDetail.member_id)
    setValue("name" ,memberDetail.user_name)
    setValue("mobileNumber" ,memberDetail.mobile_number)
    setValue("email" ,memberDetail.email)
    setValue("aadharNumber" ,memberDetail.aadhar_number)
    setValue("panNumber" ,memberDetail.pan_number)
    setValue("houseNo" ,memberDetail.address.house_no)
    setValue("streetAddress" ,memberDetail.address.street_address)
    setValue("postalCode" ,memberDetail.address.postal_code)
    setValue("city" ,memberDetail.city)
    setValue("state" ,memberDetail.state)
    setValue("accountHolderName" ,memberDetail.account_details.account_holder_address)
    setValue("accountIfscCode" ,memberDetail.account_details.bank_ifsc_code)
    // setValue("userPackageType" ,memberDetail.account_details.name)
    setValue("accountNumber" ,memberDetail.account_details.account_number)
      
    
    setScreenType("ChangeUserDetail")
  };
  const handleAddMember = async (data) => {
    dispatch({ type: "SET_LOADING_STATUS", status: true });
    window.scrollTo(0, 0)
    console.log(data);
    const re = await addMember(data);
    console.log(re);
    if (re?.status === 200) {
      toast.success(re?.data?.message);
      setScreenType("memberDetail");
      reset()
      refetch();
    } else if (re.response.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else if (re.response.status === 409) {
      toast.error("Email is already used.");
    } else {
      toast.error(re?.response?.data?.message);
    }



   
    dispatch({ type: "SET_LOADING_STATUS", status: false });
  };
  const handleChangeMemberDeatil = async (data) => {
    dispatch({ type: "SET_LOADING_STATUS", status: true });
    window.scrollTo(0, 0)
    console.log(data);
    const re = await changeMember(data);
    console.log(re);
    if (re?.status === 200) {
      toast.success(re?.data?.message);
      setScreenType("memberDetail");
      refetch();
    } else if (re.response.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else if (re.response.status === 409) {
      toast.error("Email is already used.");
    } else {
      toast.error(re?.response?.data?.message);
    }
    dispatch({ type: "SET_LOADING_STATUS", status: false });
  };
  const reSendLink = async (userId) => {
    dispatch({ type: "SET_LOADING_STATUS", status: true });
    window.scrollTo(0, 0)
    console.log(userId);
    const re = await resendPaymentLink({userId});
    console.log(re);
    if (re?.status === 200) {
      toast.success(re?.data?.message);
      refetch()
      setScreenType("memberDetail");
    } else if (re.response.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else if (re.response.status === 409) {
      toast.error("Something went wrong.");
    } else {
      toast.error(re?.response?.data?.message);
    }
    dispatch({ type: "SET_LOADING_STATUS", status: false });
  };
  if (isMemberListLoading) {
    return <Loader />;
  }
  switch (screenType) {
    case "memberDetail":
      return (
        <section className="add-member">
          <div className="member-detail-wrapper">
            <div className="title">Members Details</div>
            {!isMemberListLoading && (
              <>
                {memberListData?.status === 200 ? (
                  <div className="member-details-container">
                    {memberListData?.data?.data.map((detail) => (
                      <div className="member-detail-card">
                        {!detail?.is_verify && (
                          <div className="edite-btn-wrapper" onClick={()=>changeMemberDetail(detail)}>Edit</div>
                        )}
                        <div className="detail-inner-container">
                          <div className="detail-wrapper">
                            <div className="profile-pic">
                              <img src={profile_pic} alt="" />
                            </div>
                            <div className="member-detail">
                              <div className="name">{detail?.user_name}</div>
                              <div className="address">
                                {detail?.address?.street_address}
                              </div>
                              <div className="joining-date">
                                {detail?.created_at?convertToDate(detail?.created_at):""}
                              </div>
                              <div className="phone-number">
                                {detail?.mobile_number}
                              </div>
                              <div className="email">{detail?.email}</div>
                            </div>
                          </div>
                          <div className="payment-status">
                            {!detail?.is_verify ? (
                              <>
                                <div className="status-pending">
                                  Payment Pending
                                  <RStatus />
                                </div>
                                <div className="action-btn">
                                  <button className="primary-btn" onClick={()=>{navigator.clipboard.writeText(detail.payment_link);toast.success("Copied");}}>
                                    Copy Payment Link
                                  </button>
                                  <button className="green-btn" onClick={()=>reSendLink(detail._id)}>
                                    Resend Payment Link
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div className="status-done">
                                Payment Done <GStatus />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="server-error">
                    <img src={error} alt="" />
                    <span>Something went wrong.</span>
                  </div>
                )}
              </>
            )}
          </div>
          {memberListData?.data?.data.length === 4 ? (
            <div className="message">
              {`🚀 You've successfully achieved your goal.`}
            </div>
          ) : (
            <div className="message">
              {`Almost there! 🚀 Just ${
                numberToString[4 - memberListData?.data?.data.length]
              } more member needed to reach your goal. Let's
        add ${
          numberToString[4 - memberListData?.data?.data.length]
        } more to the team and achieve greatness together! 💪
        #TeamSuccess`}
            </div>
          )}
          {memberListData?.data?.data.length < 4 && (
            <div className="add-new-member-btn">
              <button
                className="green-btn"
                onClick={() => setScreenType("addMemberForm")}
              >
                Add Another Member to Achieve a Milestone
              </button>
            </div>
          )}
        </section>
      );
    case "addMemberForm":
      return (
        <section className="add-member-form">
          <form onSubmit={handleSubmit((data) => handleAddMember(data))}>
            <div className="form-section-1 inner-form">
              <div className="title">Personal Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Name. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...register("name", {
                      required: "Name is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Name."
                  />
                  {errors.name && (
                    <p className="errorMsg">{errors.name.message}</p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Mobile No. <span>*</span>
                  </label>
                  <input
                    type="number"
                    {...register("mobileNumber", {
                      required: "Mobile number is required.",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Mobile number is not valid.",
                      },
                    })}
                    placeholder="Enter Mobile No."
                  />
                  {errors.mobileNumber && (
                    <p className="errorMsg">{errors.mobileNumber.message}</p>
                  )}
                </div>

                <div className="input-container">
                  <label htmlFor="">
                    Email. <span>*</span>
                  </label>
                  <input
                    type="email"
                    {...register("email", {
                      required: "Email is required.",
                      pattern: {
                        value: "/^[^@ ]+@[^@ ]+.[^@ .]{2,}$/",
                        message: "Email is not valid.",
                      },
                    })}
                    placeholder="Enter Email."
                  />
                  {errors.email && (
                    <p className="errorMsg">{errors.email.message}</p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Aadhar No. <span>*</span>
                  </label>
                  <input
                    type="number"
                    {...register("aadharNumber", {
                      required: "Aadhar number is required.",
                      pattern: {
                        value: /^[0-9]{12}/,
                        message: "Aadhar number is not valid.",
                      },
                    })}
                    placeholder="Enter Aadhar No."
                  />
                  {errors.aadharNumber && (
                    <p className="errorMsg">{errors.aadharNumber.message}</p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    PAN No.
                  </label>
                  <input
                    type="text"
                    {...register("panNumber", {
                      // required: "PAN number is required.",
                      pattern: {
                        value: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
                        message: "PAN number is not valid.",
                      },
                    })}
                    onChange={(event)=>handlePanChange(event)}
                    placeholder="Enter PAN No."
                  />
                  {errors.panNumber && (
                    <p className="errorMsg">{errors.panNumber.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section-2 inner-form">
              <div className="title">Address Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    House Name & No. 
                  </label>
                  <input
                    type="text"
                    {...register("houseNo", {
                      // required: "House name & number is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter House Name & No."
                  />
                  {errors.houseNo && (
                    <p className="errorMsg">{errors.houseNo.message}</p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Street Address. 
                  </label>
                  <input
                    type="text"
                    {...register("streetAddress", {
                      // required: "Street address is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Street Address."
                  />
                  {errors.streetAddress && (
                    <p className="errorMsg">{errors.streetAddress.message}</p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Postal Code. <span>*</span>
                  </label>
                  <input
                    type="number"
                    {...register("postalCode", {
                      required: "Postal code is required.",
                      pattern: {
                        value: /^[0-9]{6}/,
                        message: "Postal code is not valid.",
                      },
                    })}
                    placeholder="Enter Postal Code."
                  />
                  {errors.postalCode && (
                    <p className="errorMsg">{errors.postalCode.message}</p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    State. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...register("state", {
                      required: "State is required.",
                      maxLength: {
                        value: 20,
                        message: "length should be less then 20.",
                      },
                    })}
                    placeholder="Enter State."
                  />
                  {errors.state && (
                    <p className="errorMsg">{errors.state.message}</p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    City. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...register("city", {
                      required: "City is required.",
                      maxLength: {
                        value: 20,
                        message: "length should be less then 20.",
                      },
                    })}
                    placeholder="Enter City."
                  />
                  {errors.city && (
                    <p className="errorMsg">{errors.city.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section-3 inner-form">
              <div className="title">Account Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Account Holder Name. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...register("accountHolderName", {
                      required: "Account holder name is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Account Holder Name."
                  />
                  {errors.accountHolderName && (
                    <p className="errorMsg">
                      {errors.accountHolderName.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    IFSC Code. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...register("accountIfscCode", {
                      required: "IFSC code is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter IFSC Code."
                  />
                  {errors.accountIfscCode && (
                    <p className="errorMsg">{errors.accountIfscCode.message}</p>
                  )}
                </div>

                <div className="input-container">
                  <label htmlFor="">
                    Account No. <span>*</span>
                  </label>
                  <input
                    type="number"
                    {...register("accountNumber", {
                      required: "Account number is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Account No."
                  />
                  {errors.accountNumber && (
                    <p className="errorMsg">{errors.accountNumber.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section-4 inner-form">
              <div className="title">Package Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Package. <span>*</span>
                  </label>
                  <select
                    type="text"
                    {...register("userPackageType", {
                      required: "Please select package",
                    })}
                    placeholder="Enter Account Holder Name."
                    defaultValue="BASIC"
                  >
                    <option value="BASIC">550/- </option>
                    <option value="PRO">1350/- </option>
                  </select>
                  {errors.userPackageType && (
                    <p className="errorMsg">{errors.userPackageType.message}</p>
                  )}
                  <p className="note">
                    {" "}
                    <span>Note:-</span>During payment, 18% GST is charged. The
                    value of this page is GST excluded.
                  </p>
                </div>
              </div>
            </div>
            <div className="btn-wrapper">
              <button className="green-btn submit-btn">Add Member</button>
            </div>
          </form>
        </section>
      );
    case "ChangeUserDetail":
      return (
        <section className="add-member-form">
          <form
            onSubmit={handleChangeUserDetail((data) => handleChangeMemberDeatil(data))}
          >
            <div className="form-section-1 inner-form">
              <div className="title">Personal Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Name. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("name", {
                      required: "Name is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Name."
                  />
                  {changeUserDetailError.name && (
                    <p className="errorMsg">
                      {changeUserDetailError.name.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Mobile No. <span>*</span>
                  </label>
                  <input
                    type="number"
                    {...changeUserDetail("mobileNumber", {
                      required: "Mobile number is required.",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Mobile number is not valid.",
                      },
                    })}
                    placeholder="Enter Mobile No."
                  />
                  {changeUserDetailError.mobileNumber && (
                    <p className="errorMsg">
                      {changeUserDetailError.mobileNumber.message}
                    </p>
                  )}
                </div>

                <div className="input-container">
                  <label htmlFor="">
                    Email. <span>*</span>
                  </label>
                  <input
                    type="email"
                    {...changeUserDetail("email", {
                      required: "Email is required.",
                      pattern: {
                        value: "/^[^@ ]+@[^@ ]+.[^@ .]{2,}$/",
                        message: "Email is not valid.",
                      },
                    })}
                    placeholder="Enter Email."
                  />
                  {changeUserDetailError.email && (
                    <p className="errorMsg">
                      {changeUserDetailError.email.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Aadhar No. <span>*</span>
                  </label>
                  <input
                    type="number"
                    {...changeUserDetail("aadharNumber", {
                      required: "Aadhar number is required.",
                      pattern: {
                        value: /^[0-9]{12}/,
                        message: "Aadhar number is not valid.",
                      },
                    })}
                    placeholder="Enter Aadhar No."
                  />
                  {changeUserDetailError.aadharNumber && (
                    <p className="errorMsg">
                      {changeUserDetailError.aadharNumber.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    PAN No. 
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("panNumber", {
                      // required: "PAN number is required.",
                      pattern: {
                        value: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
                        message: "PAN number is not valid.",
                      },
                    })}
                    onChange={(event)=>handlePanChange(event)}
                    placeholder="Enter PAN No."
                  />
                  {changeUserDetailError.panNumber && (
                    <p className="errorMsg">{changeUserDetailError.panNumber.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section-2 inner-form">
              <div className="title">Address Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    House Name & No. 
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("houseNo", {
                      // required: "House name & number is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter House Name & No."
                  />
                  {changeUserDetailError.houseNo && (
                    <p className="errorMsg">
                      {changeUserDetailError.houseNo.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Street Address. 
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("streetAddress", {
                      // required: "Street address is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Street Address."
                  />
                  {changeUserDetailError.streetAddress && (
                    <p className="errorMsg">
                      {changeUserDetailError.streetAddress.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Postal Code. <span>*</span>
                  </label>
                  <input
                    type="number"
                    {...changeUserDetail("postalCode", {
                      required: "Postal code is required.",
                      pattern: {
                        value: /^[0-9]{6}/,
                        message: "Postal code is not valid.",
                      },
                    })}
                    placeholder="Enter Postal Code."
                  />
                  {changeUserDetailError.postalCode && (
                    <p className="errorMsg">
                      {changeUserDetailError.postalCode.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    State. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("state", {
                      required: "State is required.",
                      maxLength: {
                        value: 20,
                        message: "length should be less then 20.",
                      },
                    })}
                    placeholder="Enter State."
                  />
                  {changeUserDetailError.state && (
                    <p className="errorMsg">
                      {changeUserDetailError.state.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    City. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("city", {
                      required: "City is required.",
                      maxLength: {
                        value: 20,
                        message: "length should be less then 20.",
                      },
                    })}
                    placeholder="Enter City."
                  />
                  {changeUserDetailError.city && (
                    <p className="errorMsg">
                      {changeUserDetailError.city.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section-3 inner-form">
              <div className="title">Account Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Account Holder Name. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("accountHolderName", {
                      required: "Account holder name is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Account Holder Name."
                  />
                  {changeUserDetailError.accountHolderName && (
                    <p className="errorMsg">
                      {changeUserDetailError.accountHolderName.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    IFSC Code. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...changeUserDetail("accountIfscCode", {
                      required: "IFSC code is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter IFSC Code."
                  />
                  {changeUserDetailError.accountIfscCode && (
                    <p className="errorMsg">
                      {changeUserDetailError.accountIfscCode.message}
                    </p>
                  )}
                </div>

                <div className="input-container">
                  <label htmlFor="">
                    Account No. <span>*</span>
                  </label>
                  <input
                    type="number"
                    {...changeUserDetail("accountNumber", {
                      required: "Account number is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Account No."
                  />
                  {changeUserDetailError.accountNumber && (
                    <p className="errorMsg">
                      {changeUserDetailError.accountNumber.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section-4 inner-form">
              <div className="title">Package Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Package. <span>*</span>
                  </label>
                  <select
                    type="text"
                    {...changeUserDetail("userPackageType", {
                      required: "Please select package",
                    })}
                    placeholder="Enter Account Holder Name."
                    defaultValue="BASIC"
                  >
                    <option value="BASIC">550/- </option>
                    <option value="PRO">1350/- </option>
                  </select>
                  {changeUserDetailError.userPackageType && (
                    <p className="errorMsg">
                      {changeUserDetailError.userPackageType.message}
                    </p>
                  )}
                  <p className="note">
                    {" "}
                    <span>Note:-</span>During payment, 18% GST is charged. The
                    value of this page is GST excluded.
                  </p>
                </div>
              </div>
            </div>
            <div className="btn-wrapper">
              <button className="green-btn submit-btn">Update Member Detail</button>
            </div>
          </form>
        </section>
      );
  }
}

export default DashboardAddMember;
