import { useState } from "react";
import "./dashboard-members.style.scss";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import { allMemberList } from "../../../../apiCall";
import { useQuery } from "@tanstack/react-query";
import { useStateValue } from "../../../../StateProvider";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../../../components/loader/loader.component";
import { MdNavigateNext } from "react-icons/md";
import { RxCheckCircled } from "react-icons/rx";
import { RxCrossCircled } from "react-icons/rx";

const convertToDate = (str) => {
  var date = new Date(str);

  // Extract year, month, and day
  var year = date.getFullYear();
  // Note: JavaScript months are zero-based, so we add 1 to get the correct month
  var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Zero padding
  var day = date.getDate().toString().padStart(2, "0"); // Zero padding

  // Construct the desired date string
  var formattedDate = day + "-" + month + "-" + year;
  return formattedDate;
};

const COLUMNS = [
  {
    label: "Member Id",
    renderCell: (item) => item.member_id,
    sort: { sortKey: "MEMBER_ID" },
  },
  {
    label: "Member Name",
    renderCell: (item) => item.user_name,
    sort: { sortKey: "MEMBER_NAME" },
  },
  {
    label: "Package Amount",
    renderCell: (item) =>
      (item?.user_package_type === "PRO" ? "1350" : "550").toString(),
    sort: { sortKey: "jOINING_DATE" },
  },
  {
    label: "Joining Date",
    renderCell: (item) =>
      (item?.created_at ? convertToDate(item?.created_at) : "").toString(),
    sort: { sortKey: "jOINING_DATE" },
  },
  {
    label: "Is Verify",
    renderCell: (item) => {
      if (item?.is_verify) {
        return (
          <div className="unblock">
            <RxCheckCircled />
          </div>
        );
      } else {
        return  (<div className="block">
        <RxCrossCircled />
      </div>);
       
      }
    },
    sort: { sortKey: "jOINING_DATE" },
  },
  {
    label: "City",
    renderCell: (item) => item.city,
    sort: { sortKey: "CITY" },
  },
];
function DashboardMembers(props) {
  const perPage = 10;

  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);

  const onSuccess = (re) => {
    if (re?.status === 200) {
      setMemberData(re?.data?.data);
    } else if (re?.response?.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else {
      toast.error("Something went wrong.");
    }
  };
  const {
    isLoading: isMemberListLoading,
    data: memberListData,
    refetch,
  } = useQuery({
    queryKey: ["all-member-list", pageNumber],
    queryFn: () => allMemberList(pageNumber, perPage),

    onSuccess,
  });
  const nextPage = () => {
    setPageNumber((prevPage) => prevPage + 1);
    // refetch()
  };
  const previousPage = () => {
    setPageNumber((prevPage) => prevPage - 1);
    // refetch()
  };
  const [memberData, setMemberData] = useState(
    memberListData?.data?.data ? memberListData?.data?.data : []
  );
  const pagination = usePagination(
    { nodes: memberListData?.data?.data ? memberListData?.data?.data : [] },
    {
      state: {
        page: 0,
        size: 10,
      },
      onChange: onPaginationChange,
    }
  );
  function onPaginationChange(action, state) {
    console.log(action, state);
  }
  function onSortChange(action, state) {
    console.log(action, state);
  }
  const sort = useSort(
    { nodes: memberListData?.data?.data ? memberListData?.data?.data : [] },
    {
      onChange: onSortChange,
    },
    {
      sortFns: {
        MEMBER_ID: (array) =>
          array.sort((a, b) => a.member_id.localeCompare(b.member_id)),
        MEMBER_NAME: (array) =>
          array.sort((a, b) => a.user_name.localeCompare(b.user_name)),
        TRANSACTION_DATE: (array) =>
          array.sort((a, b) =>
            a.transactionDate.localeCompare(b.transactionDate)
          ),
        CITY: (array) => array.sort((a, b) => a.city.localeCompare(b.city)),
      },
    }
  );
  const theme = useTheme([
    getTheme(),
    {
      HeaderRow: `
        background-color: #eaf5fd;
      `,
      Row: `
        &:nth-of-type(odd) {
          background-color: #d2e9fb;
        }

        &:nth-of-type(even) {
          background-color: #eaf5fd;
        }
      `,
    },
  ]);
  if (isMemberListLoading) {
    return <Loader />;
  }
  return (
    <section className="member">
      <div className="history-container">
        <div className="header-wrapper">
          <div className="title">Member List</div>
          <div className="filter">
            {/* <select name="" id="">
              <option value="Last 30 days">Last 30 days</option>
            </select> */}
          </div>
        </div>
        <div className="table-wrapper">
          <CompactTable
            columns={COLUMNS}
            data={{ nodes: memberData.length > 0 ? memberData[0].list : [] }}
            theme={theme}
            // sort={sort}
            pagination={pagination}
          />
        </div>
      </div>
      {memberListData?.data?.data.length > 0 ? (
        <div className="pagination">
          <button
            type="button"
            className={`page-index ${
              pageNumber === 1 ? "" : "active"
            } previous-btn`}
            disabled={pageNumber === 1}
            onClick={() => previousPage()}
          >
            <MdNavigateNext size={30} />
          </button>
          <div className="page-count">{pageNumber}</div>
          <button
            type="button"
            className={`page-index ${
              Math.ceil(memberListData?.data?.data[0].count.count / perPage) ===
              pageNumber
                ? ""
                : "active"
            } next-btn`}
            disabled={
              Math.ceil(memberListData?.data?.data[0].count.count / perPage) ===
                pageNumber || memberListData?.data?.data[0].count.count === 0
            }
            onClick={() => nextPage()}
          >
            <MdNavigateNext size={30} />
          </button>
        </div>
      ) : (
        ""
      )}
    </section>
  );
}

export default DashboardMembers;
