import React, { useState } from "react";
import { useForm } from "react-hook-form";

import "./dashboard-profile.style.scss";
import profile_img from "../../../../assets/image/default_profile_pic.png";
import profile_arrow from "../../../../assets/image/dashboard/profile-arrow.svg";
import { useQuery } from "@tanstack/react-query";
import {
  changeUserPassword,
  getUserDetail,
  updateUserDetail,
} from "../../../../apiCall";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../../StateProvider";
import Loader from "../../../../components/loader/loader.component";

function DashboardProfile(props) {
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [activatUpdateFormType, setActiveUpdateFormType] = useState("");
  const handlePanChange = (e) => {
    setPersonalDetailValue("panNumber", e.target.value.toUpperCase(), {
      shouldValidate: true,
    });
  };

  const handleSubmit = async (data) => {
    reset({
      oldPassword: "",
      newPassword: "",
    });

    const re = await updateUserDetail(data);
    console.log(re);
    if (re?.status === 200) {
      toast.success("Updated Successfuly.");
      setActiveUpdateFormType("");
    } else if (re?.response?.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired.");
    } else {
      toast.error("Something went wrong. please reload page.");
    }
    reset();
  };
  const handleChangePassword = async (data) => {
    const re = await changeUserPassword(data);
    console.log(re);
    if (re?.status === 200) {
      toast.success("Updated Successfuly.");
      reset({
        oldPassword: "",
        newPassword: "",
      });
    } else if (re?.response?.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else if (re?.response?.status === 409) {
      toast.error("Old Password is incorrect.");
    } else {
      toast.error("Something went wrong. please reload page.");
    }
    setActiveUpdateFormType("");
  };

  const onSuccess = (re) => {
    if (re?.status === 200) {
      // *************************** set personal value start ***********************
      setPersonalDetailValue("name", re?.data?.data?.user_name);
      setPersonalDetailValue("email", re?.data?.data?.email);
      setPersonalDetailValue("mobileNumber", re?.data?.data?.mobile_number);
      setPersonalDetailValue("aadharNumber", re?.data?.data?.aadhar_number);
      setPersonalDetailValue("panNumber", re?.data?.data?.pan_number);
      // *************************** set personal value end ***********************
      // *************************** set address value start ***********************
      setAddressDetailValue("houseNo", re?.data?.data?.address?.house_no);
      setAddressDetailValue(
        "streetAddress",
        re?.data?.data?.address?.street_address
      );
      setAddressDetailValue("postalCode", re?.data?.data?.address?.postal_code);
      setAddressDetailValue("city", re?.data?.data?.city);
      setAddressDetailValue("state", re?.data?.data?.state);
      // *************************** set address value end ***********************
      // *************************** set account value start ***********************
      setAccountDetailValue(
        "accountHolderName",
        re?.data?.data?.account_details?.account_holder_address
      );
      setAccountDetailValue(
        "accountIfscCode",
        re?.data?.data?.account_details?.bank_ifsc_code
      );
      setAccountDetailValue(
        "accountNumber",
        re?.data?.data?.account_details?.account_number
      );
      // *************************** set account value end ***********************
    } else if (re?.response?.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else {
      toast.error("Something went wrong.");
    }
  };
  const {
    isLoading: isUserDataLoading,
    data: userData,
    refetch,
  } = useQuery({
    queryKey: ["user-detail"],
    queryFn: getUserDetail,

    onSuccess,
  });

  const {
    register: registerPersonalDetails,
    handleSubmit: handleSubmitPersonalDetails,
    formState: { errors: errorsPersonalDetails },
    setValue: setPersonalDetailValue,
  } = useForm({
    defaultValues: {
      type: "PERSONAL_DETAILS",
      countryCode: "+91",
      name: userData?.data?.data?.user_name,
      email: userData?.data?.data?.email,
      mobileNumber: userData?.data?.data?.mobile_number,
      aadharNumber: userData?.data?.data?.aadhar_number,
    },
  });

  const {
    register: registerAddressDetails,
    handleSubmit: handleSubmitAddressDetails,
    formState: { errors: errorsAddressDetails },
    setValue: setAddressDetailValue,
  } = useForm({
    defaultValues: {
      type: "ADDRESS_DETAILS",
      houseNo: userData?.data?.data?.address?.house_no,
      streetAddress: userData?.data?.data?.address?.street_address,
      postalCode: userData?.data?.data?.address?.postal_code,
      city: userData?.data?.data?.city,
      state: userData?.data?.data?.state,
    },
  });

  const {
    register: registerAccountDetails,
    handleSubmit: handleSubmitAccountDetails,
    formState: { errors: errorsAccountDetails },
    setValue: setAccountDetailValue,
  } = useForm({
    defaultValues: {
      type: "ACCOUNT_DETAILS",
      accountHolderName:
        userData?.data?.data?.account_details?.account_holder_address,
      accountIfscCode: userData?.data?.data?.account_details?.bank_ifsc_code,
      accountNumber: userData?.data?.data?.account_details?.account_number,
    },
  });

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: errorsPassword },
    reset,
  } = useForm({
    defaultValues: {
      // oldPassword: "sample",
      // newPassword: "sample@sample.sample",
    },
  });
  if (isUserDataLoading) {
    return <Loader />;
  }

  return (
    <section className="profile">
      <form
        onSubmit={handleSubmitPersonalDetails((data) => handleSubmit(data))}
      >
        <div className="row-1">
          <div className="profile-img ">
            <div className="title">
              {" "}
              <div className="contant">Profile Image</div>
              {/* <div className="line-wrapper">
                <span class="caret caret-reversed"></span>{" "}
                <div class="gradient-line"></div>{" "}
              </div>{" "} */}
            </div>
            <div className="img-wrapper">
              <img src={profile_img} alt="" />
            </div>
          </div>
          <div className="form-section-1 inner-form">
            <div className="title">
              {" "}
              <div className="contant">
                Personal Details{" "}
                <span
                  className="edit-btn"
                  onClick={() => setActiveUpdateFormType("personalDetailForm")}
                >
                  Edit
                </span>
              </div>
              {/* <div className="line-wrapper">
                <span class="caret caret-reversed"></span>{" "}
                <div class="gradient-line"></div>{" "}
              </div>{" "} */}
            </div>
            <div className="input-wrapper">
              <div className="input-container">
                <label htmlFor="">
                  Name. <span>*</span>
                </label>
                <input
                  type="text"
                  {...registerPersonalDetails("name", {
                    required: "Name is required.",
                    maxLength: {
                      value: 20,
                      message: "length should be less then 20.",
                    },
                  })}
                  placeholder="Enter Name."
                  disabled={activatUpdateFormType !== "personalDetailForm"}
                />
                {errorsPersonalDetails.name && (
                  <p className="errorMsg">
                    {errorsPersonalDetails.name.message}
                  </p>
                )}
              </div>
              <div className="input-container">
                <label htmlFor="">
                  Mobile No. <span>*</span>
                </label>
                <input
                  type="number"
                  {...registerPersonalDetails("mobileNumber", {
                    required: "Mobile number is required.",
                    pattern: {
                      value: /^[0-9]{10}/,
                      message: "Mobile number is not valid.",
                    },
                  })}
                  placeholder="Enter Mobile No."
                  disabled={activatUpdateFormType !== "personalDetailForm"}
                />
                {errorsPersonalDetails.mobileNumber && (
                  <p className="errorMsg">
                    {errorsPersonalDetails.mobileNumber.message}
                  </p>
                )}
              </div>

              <div className="input-container">
                <label htmlFor="">
                  Email. <span>*</span>
                </label>
                <input
                  type="email"
                  disabled
                  {...registerPersonalDetails("email", {
                    required: "Email is required.",
                    pattern: {
                      value: /^[^@ ]+@[^@ ]+.[^@ .]{2,}$/,
                      message: "Email is not valid.",
                    },
                  })}
                  placeholder="Enter Email."
                />
                {errorsPersonalDetails.email && (
                  <p className="errorMsg">
                    {errorsPersonalDetails.email.message}
                  </p>
                )}
              </div>
              <div className="input-container">
                <label htmlFor="">
                  Aadhar No. <span>*</span>
                </label>
                <input
                  type="number"
                  {...registerPersonalDetails("aadharNumber", {
                    required: "Aadhar number is required.",
                    pattern: {
                      value: "^d{12}$",
                      message: "Aadhar number is not valid.",
                    },
                  })}
                  placeholder="Enter Aadhar No."
                  disabled={activatUpdateFormType !== "personalDetailForm"}
                />
                {errorsPersonalDetails.aadharNumber && (
                  <p className="errorMsg">
                    {errorsPersonalDetails.aadharNumber.message}
                  </p>
                )}
              </div>
              <div className="input-container">
                <label htmlFor="">
                  PAN No. 
                </label>
                <input
                  type="text"
                  {...registerPersonalDetails("panNumber", {
                    // required: "PAN number is required.",
                    pattern: {
                      value: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
                      message: "PAN number is not valid.",
                    },
                  })}
                  disabled={activatUpdateFormType !== "personalDetailForm"}
                  onChange={(event) => handlePanChange(event)}
                  placeholder="Enter PAN No."
                />
                {errorsPersonalDetails.panNumber && (
                  <p className="errorMsg">
                    {errorsPersonalDetails.panNumber.message}
                  </p>
                )}
              </div>
            </div>
            {activatUpdateFormType === "personalDetailForm" && (
              <div className="btn-wrapper">
                <button className="green-btn submit-btn">save</button>
              </div>
            )}
          </div>
        </div>
      </form>
      <form onSubmit={handleSubmitAddressDetails((data) => handleSubmit(data))}>
        <div className="form-section-2 inner-form">
          <div className="title">
            Address Details{" "}
            <span
              className="edit-btn"
              onClick={() => setActiveUpdateFormType("addressDetailForm")}
            >
              Edit
            </span>
          </div>
          <div className="input-wrapper">
            <div className="input-container">
              <label htmlFor="">House Name & No.</label>
              <input
                type="text"
                {...registerAddressDetails("houseNo", {
                  // required: "House name & number is required.",
                  maxLength: {
                    value: 20,
                    message: "length should be less then 20.",
                  },
                })}
                placeholder="Enter House Name & No."
                disabled={activatUpdateFormType !== "addressDetailForm"}
              />
              {errorsAddressDetails.houseNo && (
                <p className="errorMsg">
                  {errorsAddressDetails.houseNo.message}
                </p>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">Street Address.</label>
              <input
                type="text"
                {...registerAddressDetails("streetAddress", {
                  // required: "Street address is required.",
                  maxLength: {
                    value: 100,
                    message: "length should be less then 100.",
                  },
                })}
                placeholder="Enter Street Address."
                disabled={activatUpdateFormType !== "addressDetailForm"}
              />
              {errorsAddressDetails.streetAddress && (
                <p className="errorMsg">
                  {errorsAddressDetails.streetAddress.message}
                </p>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">
                Postal Code. <span>*</span>
              </label>
              <input
                type="number"
                {...registerAddressDetails("postalCode", {
                  required: "Postal code is required.",
                  pattern: {
                    value: "^d{6}$",
                    message: "Postal code is not valid.",
                  },
                })}
                placeholder="Enter Postal Code."
                disabled={activatUpdateFormType !== "addressDetailForm"}
              />
              {errorsAddressDetails.postalCode && (
                <p className="errorMsg">
                  {errorsAddressDetails.postalCode.message}
                </p>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">
                State. <span>*</span>
              </label>
              <input
                type="text"
                {...registerAddressDetails("state", {
                  required: "State is required.",
                  maxLength: {
                    value: 20,
                    message: "length should be less then 20.",
                  },
                })}
                placeholder="Enter State."
                disabled={activatUpdateFormType !== "addressDetailForm"}
              />
              {errorsAddressDetails.state && (
                <p className="errorMsg">{errorsAddressDetails.state.message}</p>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">
                City. <span>*</span>
              </label>
              <input
                type="text"
                {...registerAddressDetails("city", {
                  required: "City is required.",
                  maxLength: {
                    value: 20,
                    message: "length should be less then 20.",
                  },
                })}
                placeholder="Enter City."
                disabled={activatUpdateFormType !== "addressDetailForm"}
              />
              {errorsAddressDetails.city && (
                <p className="errorMsg">{errorsAddressDetails.city.message}</p>
              )}
            </div>
          </div>
          {activatUpdateFormType === "addressDetailForm" && (
            <div className="btn-wrapper">
              <button className="green-btn submit-btn">save</button>
            </div>
          )}
        </div>
      </form>
      <form onSubmit={handleSubmitAccountDetails((data) => handleSubmit(data))}>
        <div className="form-section-3 inner-form">
          <div className="title">
            Account Details{" "}
            <span
              className="edit-btn"
              onClick={() => setActiveUpdateFormType("accountDetailForm")}
            >
              Edit
            </span>
          </div>
          <div className="input-wrapper">
            <div className="input-container">
              <label htmlFor="">
                Account Holder Name. <span>*</span>
              </label>
              <input
                type="text"
                {...registerAccountDetails("accountHolderName", {
                  required: "Account holder name is required.",
                  maxLength: {
                    value: 50,
                    message: "length should be less then 50.",
                  },
                })}
                placeholder="Enter Account Holder Name."
                disabled={activatUpdateFormType !== "accountDetailForm"}
              />
              {errorsAccountDetails.accountHolderName && (
                <p className="errorMsg">
                  {errorsAccountDetails.accountHolderName.message}
                </p>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">
                IFSC Code. <span>*</span>
              </label>
              <input
                type="text"
                {...registerAccountDetails("accountIfscCode", {
                  required: "IFSC code is required.",
                  maxLength: {
                    value: 20,
                    message: "length should be less then 20.",
                  },
                })}
                placeholder="Enter IFSC Code."
                disabled={activatUpdateFormType !== "accountDetailForm"}
              />
              {errorsAccountDetails.accountIfscCode && (
                <p className="errorMsg">
                  {errorsAccountDetails.accountIfscCode.message}
                </p>
              )}
            </div>

            <div className="input-container">
              <label htmlFor="">
                Account No. <span>*</span>
              </label>
              <input
                type="number"
                {...registerAccountDetails("accountNumber", {
                  required: "Account number is required.",
                  maxLength: {
                    value: 50,
                    message: "length should be less then 50.",
                  },
                })}
                placeholder="Enter Account No."
                disabled={activatUpdateFormType !== "accountDetailForm"}
              />
              {errorsAccountDetails.accountNumber && (
                <p className="errorMsg">
                  {errorsAccountDetails.accountNumber.message}
                </p>
              )}
            </div>
          </div>
          {activatUpdateFormType === "accountDetailForm" && (
            <div className="btn-wrapper">
              <button className="green-btn submit-btn">save</button>
            </div>
          )}
        </div>
      </form>
      <form
        onSubmit={handleSubmitPassword((data) => handleChangePassword(data))}
      >
        <div className="form-section-3 inner-form">
          <div className="title">Change Password </div>
          <div className="input-wrapper">
            <div className="input-container">
              <label htmlFor="">
                Enter Old Password. <span>*</span>
              </label>
              <input
                type="password"
                {...registerPassword("oldPassword", {
                  required: "Old Password is required.",
                  minLength: {
                    value: 8,
                    message: "length should be greater then 8.",
                  },
                })}
                placeholder="Enter Old Password"
              />
              {errorsPassword.oldPassword && (
                <p className="errorMsg">{errorsPassword.oldPassword.message}</p>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">
                Enter New Password. <span>*</span>
              </label>
              <input
                type="password"
                {...registerPassword("newPassword", {
                  required: "New Password is required.",
                  minLength: {
                    value: 8,
                    message: "length should be greater then 8.",
                  },
                })}
                placeholder="Enter New Password."
              />
              {errorsPassword.newPassword && (
                <p className="errorMsg">{errorsPassword.newPassword.message}</p>
              )}
            </div>
          </div>
          <div className="btn-wrapper">
            <button className="primary-btn submit-btn">change password</button>
          </div>
        </div>
      </form>
    </section>
  );
}
export default DashboardProfile;
