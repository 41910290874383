import { api } from "./Constants";
import { request } from "./utils/axios-utils";

// *************************************************** login  ****************************************************************

export async function userLogin(data) {
  return request({ url: api.login, method: "post", data: data });
}
// *************************************************** admin Login  ****************************************************************

export async function adminLogin(data) {
  return request({ url: api.adminLogin, method: "post", data: data });
}
// *************************************************** add member  ****************************************************************

export async function addMember(data) {
  return request({ url: api.addMember, method: "post", data: data });
}
// ***************************************************  member list  ****************************************************************

export async function memberList() {
  return request({ url: api.memberList, method: "get" });
}
// ***************************************************  all member list  ****************************************************************

export async function allMemberList(pageNumber,perPage) {
  return request({ url: `${api.membersList}?page=${pageNumber}&perPage=${perPage}`, method: "get" });
}
// *************************************************** get user detail   ****************************************************************

export async function getUserDetail() {
  return request({ url: api.userDetail, method: "get" });
}
// *************************************************** update user detail   ****************************************************************

export async function updateUserDetail(data) {
  return request({ url: api.userDetail, method: "put", data: data });
}
// *************************************************** update user password ****************************************************************

export async function changeUserPassword(data) {
  return request({ url: api.userDetail, method: "post", data: data });
}
// *************************************************** forgot password  ****************************************************************

export async function forgotPassword(data) {
  return request({ url: api.forgotPassword, method: "post", data: data });
}
// *************************************************** reset password  ****************************************************************

export async function resetPassword(data) {
  return request({ url: api.forgotPassword, method: "put", data: data });
}
// *************************************************** change user detail    ****************************************************************

export async function changeMember(data) {
  return request({ url: api.changeMember, method: "post", data: data });
}
// *************************************************** resend payment link****************************************************************

export async function resendPaymentLink(data) {
  return request({ url: api.resendPaymentLink, method: "post", data: data });
}
// ***************************************************  wallet history    ****************************************************************

export async function walletHistory(pageNumber, perPage, type) {
  return request({
    url: `${api.walletHistory}?page=${pageNumber}&perPage=${perPage}&filterType=${type}`,
    method: "get",
  });
}
// ***************************************************  get wallet ****************************************************************

export async function getWallet() {
  return request({ url: api.wallet, method: "get" });
}
// ***************************************************  get wallet ****************************************************************

export async function withdrawRequest(data) {
  return request({ url: api.wallet, method: "post", data: data });
}
// ***************************************************  get wallet ****************************************************************

export async function support(data) {
  return request({ url: api.support, method: "post", data: data });
}

// ***************************************************  get withdraw request ****************************************************************

export async function getWithdrawAdmin(page,perPage,type) {
  return request({
    url: `${api.withdraw}?type=${type}&page=${page}&perPage=${perPage}`,
    method: "get",
  });
}
// ***************************************************  update withdraw request ****************************************************************

export async function updateWithdrawRequestAdmin(data) {
  return request({ url: api.withdraw, method: "put", data: data });
}
// ***************************************************  contact Us ****************************************************************

export async function contactUs(data) {
  return request({ url: api.contactUs, method: "post", data: data });
}
// ***************************************************  userDashboard ****************************************************************

export async function userDashboard() {
  return request({ url: api.userDashboard, method: "get" });
}
// ***************************************************  adminDashboard ****************************************************************

export async function adminDashboard() {
  return request({ url: api.adminDashboard, method: "get" });
}
// ***************************************************  adminDashboard ****************************************************************

export async function adminMemberList(pageNumber, perPage, status,email="",memberId="") {
  return request({
    url: `${api.adminMemberList}?page=${pageNumber}&perPage=${perPage}&status=${status}&email=${email}&memberId=${memberId}`,
    method: "get",
  });
}

// ***************************************************  adminUpdateMember ****************************************************************

export async function adminUpdateMember(data) {
  return request({ url: api.adminUpdateMember, method: "put", data: data });
}


// ***************************************************  supportRequest   ****************************************************************

export async function supportRequest(pageNumber, perPage, type) {
  return request({
    url: `${api.supportRequest}?page=${pageNumber}&perPage=${perPage}&status=${type}`,
    method: "get",
  });
}
// ***************************************************  supportRequestStatus ****************************************************************

export async function supportRequestStatus(data) {
  return request({ url: api.supportRequestStatus, method: "put", data: data });
}
// ***************************************************  inquiryRequest   ****************************************************************

export async function inquiryRequest(pageNumber, perPage, type) {
  return request({
    url: `${api.inquiryRequest}?page=${pageNumber}&perPage=${perPage}&status=${type}`,
    method: "get",
  });
}

// ***************************************************  inquiryRequestStatus ****************************************************************

export async function inquiryRequestStatus(data) {
  return request({ url: api.inquiryRequestStatus, method: "put", data: data });
}
// ***************************************************  userDetailChange ****************************************************************

export async function userDetailChange(data) {
  return request({ url: api.userDetailChange, method: "post", data: data });
}